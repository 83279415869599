import { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import {
  Headline,
  Banner,
  BannerHeadline,
  BannerParagraph,
} from '@dfds-ui/react-components'
import { FlexBox } from '@dfds-ui/react-components/flexbox/FlexBox'
import styled from '@emotion/styled'
import { media, theme } from '@dfds-ui/theme'
import { useTranslation } from 'react-i18next'

import { FlexCard } from '../Card'
import { gridMap } from '../../utils'
import { Markdown } from '../Markdown'

const FlexContainer = styled(FlexBox)`
  height: 100%;
  padding: 20px;
  flex: 1;
  background: ${theme.colors.surface.secondary};

  ${media.greaterThan('l')`
    border: 20px solid white;

    .cb-section-search .cb-section-inner {
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    }
    .cb-item.cb-item-search {
      width: 50%;
    }
  `}
`
const FormContainer = styled.div`
  height: 100%;
  width: 100%;

  .cb-widget-standard {
    height: 100%;
  }

  .cb-sections {
    height: 100%;
  }

  .cb-sections-inner {
    height: 100%;
    justify-content: space-evenly;
  }
`

const StyledBanner = styled(Banner)`
  width: 100%;
`

const TourOperatorWidget = ({ title, text, widgetSource }) => {
  const { t } = useTranslation()
  const [getIE, setIE] = useState(false)

  useEffect(() => {
    if (typeof navigator !== 'undefined') {
      setIE(
        navigator.userAgent.includes('MSIE ') ||
          navigator.userAgent.includes('Trident/'),
      )
    }
  }, [])

  useEffect(() => {
    const widget = document.createElement('script')
    widget.type = 'text/javascript'
    widget.async = true
    widget.src = `//${widgetSource}`
    widget.id = 'TourOperator'
    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(widget, s)
  })

  return (
    <FlexCard
      width={gridMap['full-width']}
      mediaWidth={{ md: 8 }}
      media={
        <FlexContainer>
          {getIE ? (
            <StyledBanner divider intent="warning">
              <BannerHeadline>{t('GENERAL-IE-BANNER-HEADING')}</BannerHeadline>
              <BannerParagraph>
                {t('GENERAL-IE-BANNER-FUNCTION-WARNING')}
              </BannerParagraph>
            </StyledBanner>
          ) : (
            <FormContainer id="citybreak_accommodation_ferry_package_searchform_widget" />
          )}
        </FlexContainer>
      }
      reverse
    >
      <Headline as={'h2'}>{title}</Headline>
      <Markdown source={text} />
    </FlexCard>
  )
}

export const TourOperatorWidgetFragment = graphql`
  fragment TourOperatorWidget on contentful_TourOperatorWidget {
    title
    text
    widgetSource
  }
`

export default TourOperatorWidget
